import { Controller } from 'stimulus';

export default class extends Controller {
    hidden = ["ease-in", "duration-150", "opacity-0", "scale-95"];
    shown = ["ease-out", "duration-200", "opacity-1", "scale-100"];
    clicked = false;

    static targets = ['menu'];

    open(event) {
        this.menuTarget.classList.toggle('invisible', false);
        this.hidden.forEach((value) => {this.menuTarget.classList.toggle(value, false);})
        this.shown.forEach((value) => {this.menuTarget.classList.toggle(value, true);})
    }

    close(event) {
        this.shown.forEach((value) => {this.menuTarget.classList.toggle(value, false);})
        this.hidden.forEach((value) => {this.menuTarget.classList.toggle(value, true);})

        this.invisible();
    }

    async invisible() {
        let duration = 150;

        await new Promise(() => {
            setTimeout(() => {
                this.menuTarget.classList.toggle("invisible", true);
            }, duration);
        });
    }
}
