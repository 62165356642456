import { Controller } from 'stimulus';
export default class extends Controller {
    hidden = ["ease-in", "duration-150", "opacity-0", "translate-y-1"];
    shown = ["ease-out", "duration-150", "opacity-1", "translate-y-[3.25rem]"];
    subShown = ["ease-out", "duration-150", "opacity-1", "-translate-y-[3.25rem]", "2xl:-translate-y-2"];
    clicked = false;

    static targets = ['dropdown', 'subdropdown'];

    connect() {
        window.addEventListener('click', (event)=>{
            if(this.clicked === false && !this.isDropdown(event) ) {
                this.dismiss();
            }
            else{
                this.clicked = false;
            }
        });
    }

    isDropdown(e){
        return this.dropdownTarget === e.target.closest('[data-dropdown-target="dropdown"]');
    }

    parentSubDropdown(target) {
        return target.closest('[data-dropdown-target="subdropdown"]');
    }
    subDropdown(source, target) {
        if(target.parentElement.type === "button") {
            target = target.parentElement;
        }
        return source === target.parentElement.querySelector('[data-dropdown-target="subdropdown"]');
    }

    toggle(event) {
        this.toggleTarget(this.dropdownTarget);
    }

    subToggle(event) {
        this.subdropdownTargets.forEach((subdropdown) => {
            if(this.subDropdown(subdropdown, event.target)) {
                this.toggleTarget(subdropdown, false);
            } else if(subdropdown === this.parentSubDropdown(event.target)) {
                this.toggleTarget(subdropdown, false);
            }else {
                this.targetDismiss(subdropdown, false);
            }
        });
        this.clicked = true;
    }

    toggleTarget(target, isDropdown = true) {
        let classList = [...target.classList];

        if (classList.includes("invisible")) {
            target.classList.toggle('invisible', false);
            this.hidden.forEach((value) => {target.classList.toggle(value, false);})
            if (isDropdown){
                this.shown.forEach((value) => {target.classList.toggle(value, true);})
            }else{
                this.subShown.forEach((value) => {target.classList.toggle(value, true);})
            }

            this.clicked = true;
        }
    }

    dismiss() {
        this.subdropdownTargets.forEach((subdropdown)=>{
            this.targetDismiss(subdropdown, false);
        })
        this.targetDismiss(this.dropdownTarget);
    }

    targetDismiss(target, isDropdown = true) {
        if (isDropdown){
            this.shown.forEach((value) => {target.classList.toggle(value, false);})
        }else{
            this.subShown.forEach((value) => {target.classList.toggle(value, false);})
        }
        this.hidden.forEach((value) => {target.classList.toggle(value, true);})

        this.invisible(target);
    }

    async invisible(target) {
        let duration = 150;

        await new Promise(() => {
            setTimeout(() => {
                target.classList.toggle("invisible", true);
            }, duration);
        });
    }
}