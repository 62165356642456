import '@nucleos/maps-bundle/dist/openstreetmap.css';
import controller_0 from '@symfony/ux-chartjs/dist/controller.js';
import { Controller } from '@hotwired/stimulus';
export default {
  'nucleos--maps-bundle--openstreetmap': class extends Controller {
      constructor(context) {
          super(context);
          this.__stimulusLazyController = true;
      }
      initialize() {
          if (this.application.controllers.find((controller) => {
              return controller.identifier === this.identifier && controller.__stimulusLazyController;
          })) {
              return;
          }
          import('@nucleos/maps-bundle/dist/openstreetmap_controller.js').then((controller) => {
              this.application.register(this.identifier, controller.default);
          });
      }
  },
  'nucleos--maps-bundle--googlemaps': class extends Controller {
      constructor(context) {
          super(context);
          this.__stimulusLazyController = true;
      }
      initialize() {
          if (this.application.controllers.find((controller) => {
              return controller.identifier === this.identifier && controller.__stimulusLazyController;
          })) {
              return;
          }
          import('@nucleos/maps-bundle/dist/googlemaps_controller.js').then((controller) => {
              this.application.register(this.identifier, controller.default);
          });
      }
  },
  'symfony--ux-chartjs--chart': controller_0,
};