import '@fortawesome/fontawesome-free/js/all';
import './styles/app.css';
import './images/logo.png';
import './images/bior.png';
import './images/gold.png';
import './images/pink.png';
import './images/silver.png';
import './images/visuel_indisponible.png';
import './js/menu';
import './js/backToTop';
import './bootstrap';
import 'flowbite';
