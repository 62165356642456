/* DROPDOWN */
// console.debug(" DROPDOWN tailwindcss")
/*
'Solutions' flyout menu, show/hide based on flyout menu state.
*/

window.addEventListener("DOMContentLoaded", (event) =>
{
    document.querySelectorAll(":scope [aria-label=\"tailwind-dropdown\"]").forEach((ariaDropdownButton) =>
    {
        // Dropdown button
        ariaDropdownButton.addEventListener("click", myDropdownClick.bind(ariaDropdownButton), false);
        myDropdownClose("");
    });
    window.addEventListener("click", ()=>{
        event.stopPropagation();
        myDropdownClose("");
    });
});

function myDropdownClick(event) {
    event.stopPropagation();
    myDropdownClose(this.nextElementSibling);
    myDropdownGuesser(this.nextElementSibling);
}

/*
Leaving: "ease-in duration-150 opacity-0 translate-y-1"
*/
function myDropdownLeaving(element, state) {
    if(!state){
        element.classList.toggle("invisible",state);
    }
    element.classList.toggle("ease-in",state);
    element.classList.toggle("duration-150",state);
    element.classList.toggle("opacity-0", state);
    element.classList.toggle("translate-y-1", state);
}

/*
Entering: "ease-out duration-200 opacity-100 translate-y-0"
* */
function myDropdownEntering(element, state) {
    element.classList.toggle("ease-out",state);
    element.classList.toggle("duration-200",state);

    element.classList.toggle("opacity-100", state);
    element.classList.toggle("translate-y-0", state);

}

/*
Timeout function to delay invisible handler to match transition duration
 */
async function myDropdownHide(element) {
    await new Promise(()=>{
        setTimeout(()=>{
            if([...element.classList].includes('opacity-0')) {
                element.classList.toggle("invisible", true)
            }
        }, 150)
    });
}

function myDropdownGuesser(element) {
    // Cast DOMTokenList to array
    let classList = [...element.classList];

    if (classList.includes("opacity-0")) {
        myDropdownLeaving(element, false);
        myDropdownEntering(element, true);
    }
    else {
        myDropdownEntering(element, false);
        myDropdownLeaving(element, true);
        myDropdownHide(element);
    }
}

/**
 * Function that close all other dropdown than the one opened with a click
 */
function myDropdownClose(source) {
    document.querySelectorAll(":scope [aria-label=\"tailwind-dropdown\"]").forEach((element)=>
    {
        deselectDropdown(element.nextElementSibling, source);
    });
}

function deselectDropdown(element, source) {
    if(element !== source) {
        myDropdownEntering(element, false);
        myDropdownLeaving(element, true);
        myDropdownHide(element);
    }
}
